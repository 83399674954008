import Image from "./image"
import React from "react"
import { breakpoint } from "../constants"
import { useViewport } from "../contexts/viewContext"

const CommonBackground = () => {
  const { width } = useViewport()

  return (
    <>
      {width > breakpoint ? (
        <Image filename="assets/tour/desktop/paperbg.png" />
      ) : (
        <Image filename="assets/tour/mobile/paperbg.png" />
      )}
    </>
  )
}

export default CommonBackground

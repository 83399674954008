import React, { useEffect } from "react"
import { css } from "@emotion/core"
import Floor from "../components/tour/floor"
import { breakpoint } from "../constants"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CommonBackground from "../components/CommonBackground"

const root = css`
  position: relative;
  width: 100vw;
  padding-top: 0.3rem;
  font-size: 0.2rem;
  padding-bottom: 0.3rem;
  box-sizing: border-box;
  min-height: 120vh;
  @media (max-width: ${breakpoint}px) {
    padding-top: 1rem;
  }
`

const background = css`
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`
const TourPage = () => {
  const floors = [1, 2, 3, 4]

  return (
      <div css={root}>
        <div css={background}>
          <CommonBackground />
        </div>  
        {floors.map(floor => (
          <Floor floorNum={floor} key={floor} />
        ))}
      </div>
  )
}

const Tour = () => {
  useEffect(() => {
    /* setTimeout(() => window.scrollTo(0,localStorage.getItem("tourScrollPos")), 30); */
    window.scrollTo(0,localStorage.getItem("tourScrollPos"))
    return () => localStorage.setItem("tourScrollPos", window.pageYOffset);
  })
  return (
    <Layout title="tour">
      <SEO title="TOUR" />
      <TourPage />
    </Layout>
  )
}

export default Tour

import React from "react"
import { css } from "@emotion/core"
import { StaticQuery, graphql, Link } from "gatsby"
import { breakpoint } from "./../../constants"
import PropTypes from "prop-types"

const root = css`
  width: 100vw;
  padding: 0.4rem 1rem 0 1rem;
  box-sizing: border-box;
  position: relative;
`

const floor = css`
  width: 30%;
  text-transform: uppercase;
  font-weight: bold;
  @media (max-width: ${breakpoint}px) {
    width: 80%;
  }
`

const floorTitleCss = css`
  font-size: 0.3rem;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.8rem;
  }
`

const floorNumCss = css`
  font-size: 0.5rem;
  margin-left: 0.16rem;
  @media (max-width: ${breakpoint}px) {
    font-size: 1.4rem;
    margin-left: 0.3rem;
  }
`

const roomContainer = css`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 0.7rem;
  margin-top: 0.1rem;
  align-content: flex-start;
  @media (max-width: ${breakpoint}px) {
    height: auto;
    margin-bottom: 1rem;
  }
`
const roomUnit = css`
  margin-bottom: 0.1rem;
  margin-right: 0.3rem;
  width: 1rem;
  font-size: 0.1rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  @media (max-width: ${breakpoint}px) {
    width: 4rem;
    font-size: 0.4rem;
  }
`

const line = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.03rem;
  background-color: rgba(255, 255, 255, 0.6);
  transform: rotateZ(-4deg);
  @media (max-width: ${breakpoint}px) {
    height: 0.1rem;
  }
`

const Line = () => <div css={line}></div>

const Floor = ({ floorNum }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allDataJson(filter: { slug: { regex: "/floor[1-4]/" } }) {
            edges {
              node {
                slug
                rooms {
                  roomName
                  slug
                }
              }
            }
          }
        }
      `}
      render={data => {
        const thisFloor = data.allDataJson.edges.find(n => {
          return n.node.slug === "floor" + floorNum
        })
        return (
          <div css={root}>
            {floorNum !== 1 && <Line />}
            <div css={floor}>
              <span css={floorTitleCss}>Floor</span>
              <span css={floorNumCss}>{floorNum}</span>
            </div>
            <div
              css={css`
                ${roomContainer};
                ${floorNum === 4 && "height: .3rem"}
              `}
            >
              {thisFloor.node.rooms.map((room, i) => {
                let roomUrl = room.roomName.replace(/[ '.]/g, "-") // change space or ' to -

                return (
                  <Link
                    to={`/floor${floorNum}/${roomUrl}`}
                    key={i}
                    css={roomUnit}
                  >
                    {room.roomName}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

Floor.propTypes = {
  floorNum: PropTypes.number.isRequired
}

export default Floor
